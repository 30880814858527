import React, { useEffect, useState } from 'react'
import { Card, Button, FormControl, Form } from 'react-bootstrap'
import './package.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const Package = (props: {
    header: string,
    services: any[],
    price?: number,
    color?: string,
    textFieldUpdated?: any,
    buttonText?: string,
    buttonPressed?: any,
    textFieldPlaceholder?: string,
    selected?: boolean,
    discount?: number
}) => {

    const [styleClassName, setStyleClassName] = useState("")

    useEffect(() => {
        if (props.selected) {
            setStyleClassName("selected-" + props.color ?? "primary")
            return
        }

        setStyleClassName("white-bg")

    }, [props.selected])

    const roundNearest5 = (num: number) => {
        return Math.round(num / 5) * 5;
    }

    useEffect(() => {
        console.log(styleClassName)
    }, [styleClassName])

    return (
        <Card bg={props.color ?? "primary"} >
            <Card.Header style={{ textAlign: "center", color: "white" }} ><strong>{props.header}</strong></Card.Header>
            <Card.Body style={{ color: props.selected ? "white" : "black" }} className={styleClassName} >
                <Card.Text>
                    {
                        props.services.map(s => {
                            return (<div>
                                • {s}
                            </div>)
                        })
                    }
                </Card.Text>
                {
                    props.textFieldPlaceholder &&
                    <FormControl min={0} type="number"
                        style={
                            {
                                backgroundColor: props.selected ? "lightgray" : "white"
                            }
                        }
                        disabled={props.selected == true} onChange={(e) => props.textFieldUpdated(e.target.value)} placeholder={props.textFieldPlaceholder} />
                }
                <Card.Text>
                    {
                        props.price &&
                        <div style={{ textAlign: "center" }} className="mt-4">
                            {
                                props.discount && <h4 style={{ textDecoration: "line-through" }}>${roundNearest5(props.price)}</h4>
                            }
                            <h2> ${roundNearest5(props.price * (1 - (props.discount ?? 0)))} </h2>
                        </div>
                    }
                </Card.Text>

            </Card.Body>
            <Card.Footer style={{ backgroundColor: "white", textAlign: "center" }}>
                <Button onClick={props.buttonPressed} variant={props.color ?? "primary"}>{
                    !props.selected ? (props.buttonText ?? "Select Service") :
                        <FontAwesomeIcon icon={faCheck} />
                }</Button>
            </Card.Footer>
        </Card>
    )

}

export default Package