import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Estimate } from '../estimator/models/estimate'
import { SelectedService } from '../estimator/quote'

const QuoteDetails = (props: {
    estimate: Estimate,
    mainService?: SelectedService,
    services?: SelectedService[],
    discount?: number
}) => {

    const getAddOnsPrice = () => {
        return props.services?.reduce((a, b) => a + b.price, 0)
    }

    const roundNearest5 = (num: number) => {
        return Math.round(num / 5) * 5;
    }

    return (
        <div className="printme" >
            <div className="mb-4">
                <hr />
                <Row>
                    <Col xs="10">
                        <strong>General Information</strong>
                        <div className="mt-2">{props.estimate.GeneralInformation?.floorsAboveGround}</div>
                        <div className="mt-2">{props.estimate.GeneralInformation?.houseSize}</div>
                        <div className="mt-2">{props.estimate.GeneralInformation?.residential == true ?
                            "Residential" : "Commercial"}</div>
                        <div className="mt-3"><strong>NOTES:</strong> </div>
                        {props.estimate.Misc?.additionalInfo}
                    </Col>
                </Row>
                <hr />
                <div className="my-4">
                    <h5>THE SERVICES YOU'VE SELECTED:</h5>
                </div>

                <div className="my-2"><strong>Services</strong></div>

                {
                    props.mainService &&
                    <div>
                        <Row className="my-2">
                            <Col xs="10">
                                <div>
                                    <strong>• {props.mainService?.service}</strong>
                                </div>
                            </Col>
                            <Col xs="2">
                                <strong>${roundNearest5((props.mainService?.price ?? 0) * (1 - (props.discount ?? 0)))}</strong>
                            </Col>
                        </Row>
                    </div>
                }
                {
                    props.services?.map(s => {
                        return (
                            <Row>
                                <Col xs="10">
                                    <div><strong>• {s.service} </strong>{!s.amount ? "" : `x ${s.amount}`}</div>
                                </Col>
                                <Col xs="2">
                                    <strong>${roundNearest5(s.price * (1 - (props.discount ?? 0)))}</strong>
                                </Col>
                            </Row>
                        )
                    })
                }
                <hr />
                {/* TOTAL PRICE */}
                <Row>
                    <Col xs="10">
                        <strong>Total Price</strong>
                    </Col>
                    <Col xs="2">
                        <strong>${roundNearest5(((getAddOnsPrice() ?? 0) + (props.mainService?.price ?? 0)) * (1 - (props.discount ?? 0)))}</strong>
                    </Col>
                    {
                        props.discount &&
                        <div>
                            <Col>
                                <div className="mt-3">
                                    <strong>NOTES:</strong>
                                </div>
                                <div className="mt-2">
                                    This price reflects the {props.discount * 100}% discount you've received.
                                </div>
                            </Col>
                        </div>
                    }

                </Row>
            </div>
        </div>
    )
}

export default QuoteDetails