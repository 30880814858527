import React from 'react'
import { useState } from 'react'
import { Col, Button, Form, Modal, Row, Container } from 'react-bootstrap'
import CONSTANTS from './constants/constants'
import { Estimate, GeneralInformation } from './models/estimate'

const GeneralInformationForm = (props: { setEstimate: any, estimate: Estimate, previousPage: () => void, nextPage: () => void }) => {

    const YELP = "Yelp"

    let generalInformation: GeneralInformation = props.estimate.GeneralInformation ?? {}

    const [validated, setValidated] = useState(false)

    const [showModal, setShowModal] = useState(false)

    const [showCommercialModal, setShowCommercialModal] = useState(false)

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (generalInformation.houseSize == CONSTANTS.HOUSESIZE.HUGE) {
            setShowModal(true)
            return
        }

        if (generalInformation.residential == false) {
            setShowCommercialModal(true)
            return
        }

        props.estimate.GeneralInformation = generalInformation
        props.setEstimate(props.estimate)
        props.nextPage()

        setValidated(true);
    };

    const HouseTooBig = () => {
        return (
            <Modal show={true} size="sm" onHide={() => setShowModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>House Too Big For Instant Quote</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        For houses 5000 sq ft or larger we need to do the estimate in person.
                    </p>
                    <p>
                        But don't worry, you'll still get the same great price with the discount! And all estimates are completely free of charge.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" href="tel:7027470901" onClick={() => {
                        setShowModal(false)
                    }} >Call or Text (702) 747-0901</Button>
                    <Button variant="danger" onClick={() => setShowModal(false)} >Cancel</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const CommercialJob = () => {
        return (
            <Modal show={true} size="sm" onHide={() => setShowCommercialModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Need to call</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Sorry, but you can't use the instant quote service for commercial buildings. Please call or text us now so we can provide you your estimate.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" href="tel:7027470901" onClick={() => {
                        setShowCommercialModal(false)
                    }} >Call or Text (702) 747-0901</Button>
                    <Button variant="danger" onClick={() => setShowCommercialModal(false)} >Cancel</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <div>
            {
                showModal && <HouseTooBig />
            }
            {
                showCommercialModal && <CommercialJob />
            }

            <Container>
                <Row>
                    <Col xs="12">
                        Thank you for choosing Defined Window Cleaning and Screens. Please fill out the form to receive a <strong>FREE estimate online</strong> and your <strong>{(props.estimate.Discount ?? 1) * 100}% discount.</strong> If you have any questions, please call us at (702) 747-0901.
                    </Col>
                    <Col xs="12" className="mt-3">
                        If you prefer to send a text request for a quote, you can do so by clicking the button below and we'll get back to you in minutes.
                    </Col>
                    <Col xs="12" className="mt-3">
                        <Button variant="success" href="sms:7027470901" >Click to Text Us For a FREE Quote at (702) 747-0901</Button>
                    </Col>
                    <Col className="my-3">
                        If you would like to take a look at our website, click <a href="https://definedcleaning.com" target="_blank">here.</a>
                    </Col>
                </Row>
                {
                    props.estimate.Discount &&
                    <Row>
                        <Col xs="12">
                            <div className="alert alert-success">
                                <strong>{props.estimate.Discount * 100}% Discount</strong> - In order to receive the {props.estimate.Discount * 100}% discount, you must fill out this online form and schedule an appointment using our online scheduling system.
                            </div>
                        </Col>
                    </Row>
                }
            </Container>
            <Modal.Header>
                <Modal.Title>General Information</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Row>
                        {/* How hear about us */}
                        <Form.Group as={Col} md="6" xs="12" controlId="formHowHeard">
                            <Form.Label>Where did you hear about us?</Form.Label>
                            <Form.Control
                                onChange={e => generalInformation.howHearAboutUs = e.target.value}
                                defaultValue={props.estimate.GeneralInformation?.howHearAboutUs ?? ""}
                                as="select" size="lg" custom>
                                {
                                    Object.keys(CONSTANTS.HOWFOUNDUS).map((item, index) => {
                                        // This line makes no sense to me. Not really sure why this works. Why do I have to cast item as keyof typeof CONSTANTS.HOWFOUNDUS?
                                        // Read this stackoverflow post to understand this line: https://stackoverflow.com/questions/55377365/what-does-keyof-typeof-mean-in-typescript
                                        return <option key={index}>{CONSTANTS.HOWFOUNDUS[item as keyof typeof CONSTANTS.HOWFOUNDUS]}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>

                        {/* Number of floors */}
                        <Form.Group as={Col} md="6" xs="12" controlId="formFloors">
                            <Form.Label>Number of floors above ground?</Form.Label>
                            <Form.Control as="select" size="lg"
                                onChange={e => generalInformation.floorsAboveGround = e.target.value}
                                defaultValue={props.estimate.GeneralInformation?.floorsAboveGround ?? ""} custom>
                                <option> {CONSTANTS.FLOORS.ONE_STORY} </option>
                                <option> {CONSTANTS.FLOORS.TWO_STORY} </option>
                                <option> {CONSTANTS.FLOORS.THREE_STORY} </option>
                            </Form.Control>
                        </Form.Group>

                        {/* Has basement */}
                        <Form.Group as={Col} md="6" xs="12" controlId="formBasement">
                            <Form.Label>Do you have a basement?</Form.Label>
                            <Form.Control as="select" size="lg"
                                onChange={e => generalInformation.basement = e.target.value == CONSTANTS.BASEMENT.YES ? true : false}
                                defaultValue={props.estimate.GeneralInformation?.basement == true ? CONSTANTS.YES : CONSTANTS.NO} custom>
                                <option>{CONSTANTS.BASEMENT.NO}</option>
                                <option> {CONSTANTS.BASEMENT.YES} </option>
                            </Form.Control>
                        </Form.Group>

                        {/* Has basement */}
                        <Form.Group as={Col} md="6" xs="12" controlId="formBasement">
                            <Form.Label>Do you have solar/sun screens?</Form.Label>
                            <Form.Control as="select" size="lg"
                                onChange={e => generalInformation.solarScreens = e.target.value == CONSTANTS.BASEMENT.YES ? true : false}
                                defaultValue={props.estimate.GeneralInformation?.solarScreens == true ? CONSTANTS.YES : CONSTANTS.NO} custom>
                                <option>{CONSTANTS.BASEMENT.NO}</option>
                                <option> {CONSTANTS.BASEMENT.YES} </option>
                            </Form.Control>
                        </Form.Group>

                        {/* House size */}
                        <Form.Group as={Col} md="6" xs="12" controlId="formHouseSize">
                            <Form.Label>House size</Form.Label>
                            <Form.Control as="select" size="lg"
                                onChange={e => generalInformation.houseSize = e.target.value}
                                defaultValue={props.estimate.GeneralInformation?.houseSize ?? ""} custom>
                                <option> {CONSTANTS.HOUSESIZE.SMALLEST} </option>
                                <option> {CONSTANTS.HOUSESIZE.SMALLER} </option>
                                <option> {CONSTANTS.HOUSESIZE.SMALL} </option>
                                <option> {CONSTANTS.HOUSESIZE.MEDIUM} </option>
                                <option> {CONSTANTS.HOUSESIZE.MEDIUMLARGE} </option>
                                <option> {CONSTANTS.HOUSESIZE.LARGE} </option>
                                <option> {CONSTANTS.HOUSESIZE.VERYLARGE} </option>
                                <option> {CONSTANTS.HOUSESIZE.VERYVERYLARGE} </option>
                                <option> {CONSTANTS.HOUSESIZE.HUGE} </option>
                            </Form.Control>
                        </Form.Group>


                        {/* Residential or Commercial */}
                        <Form.Group as={Col} md="6" xs="12" controlId="formHouseSize">
                            <Form.Label>Residential or Commercial</Form.Label>
                            <Form.Control
                                onChange={e => generalInformation.residential = e.target.value == CONSTANTS.RES_COMMERCIAL.RES ? true : false}
                                defaultValue={props.estimate.GeneralInformation?.residential == true ? CONSTANTS.RES_COMMERCIAL.RES : CONSTANTS.RES_COMMERCIAL.COM}
                                as="select" size="lg" custom>
                                <option> {CONSTANTS.RES_COMMERCIAL.RES} </option>
                                <option> {CONSTANTS.RES_COMMERCIAL.COM} </option>
                            </Form.Control>
                        </Form.Group>

                    </Form.Row>

                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="success" onClick={() => props.previousPage()}>Previous</Button> */}
                    <span className="mr-4" style={{ color: "gray" }}>Page 1 of 4</span>
                    <Button variant="primary" onClick={handleSubmit} >Next</Button>
                </Modal.Footer>
            </Form>
        </div>
    )
}

export default GeneralInformationForm