import React from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { Estimate, GeneralInformation } from '../models/estimate'
import CONSTANTS from '../constants/constants'
import generalInformation from '../generalInformation'

export const PressureWashingGeneralInfo = (props: { estimate: Estimate }) => {

    let generalInformation: GeneralInformation = props.estimate.GeneralInformation ?? {}

    return (
        <Container>
            <Row>
                <Col xs="12">
                    Thank you for choosing Defined Home Services. Please fill out the form to receive a <strong>FREE estimate online</strong> and your <strong>{(props.estimate.Discount ?? 1) * 100}% discount.</strong> If you have any questions, please call us at (702) 747-0901.
                </Col>
                <Col xs="12" className="mt-3">
                    If you prefer to send a text request for a quote, you can do so by clicking the button below and we'll get back to you in minutes.
                </Col>
                <Col xs="12" className="mt-3">
                    <Button variant="success" href="sms:7027470901" >Click to Text Us For a FREE Quote at (702) 747-0901</Button>
                </Col>
                <Col className="my-3">
                    If you would like to take a look at our website, click <a href="https://definedcleaning.com/pressurewashing" target="_blank">here.</a>
                </Col>
            </Row>
            {
                props.estimate.Discount &&
                <Row>
                    <Col xs="12">
                        <div className="alert alert-success">
                            <strong>{props.estimate.Discount * 100}% Discount</strong> - In order to receive the {props.estimate.Discount * 100}% discount, you must fill out this online form and schedule an appointment using our online scheduling system.
                        </div>
                    </Col>

                    <Col className="my-5" xs="12" style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100vh"
                    }}>
                        <Form.Group as={Col} md="6" xs="12" controlId="formHowHeard" >
                            <Form.Label style={{ textAlign: "center" }}><h1>Which services would you like done?</h1></Form.Label>
                            {
                                Object.keys(CONSTANTS.SERVICES.PRESSURE_WASHING_GROUP).map((item, index) => {
                                    // This line makes no sense to me. Not really sure why this works. Why do I have to cast item as keyof typeof CONSTANTS.HOWFOUNDUS?
                                    // Read this stackoverflow post to understand this line: https://stackoverflow.com/questions/55377365/what-does-keyof-typeof-mean-in-typescript
                                    // return <option key={index}>{CONSTANTS.SERVICES.PRESSURE_WASHING_GROUP[item as keyof typeof CONSTANTS.SERVICES.PRESSURE_WASHING_GROUP]}</option>
                                    return <Form.Check style={{ fontSize: 25 }} className="my-1" type="checkbox" key={index} label={CONSTANTS.SERVICES.PRESSURE_WASHING_GROUP[item as keyof typeof CONSTANTS.SERVICES.PRESSURE_WASHING_GROUP]} />
                                })
                            }


                            <Button className="mt-5" style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%"
                            }}>Next</Button>
                        </Form.Group>
                    </Col>
                </Row>
            }

        </Container >
    )
}